<template>
  <div>
    <v-simple-table>
      <template v-slot:default v-if="items.length">
        <thead>
          <tr>
            <th>Indicador</th>
            <th class="text-center">Tipo Meta</th>
            <th class="text-center">Meta Valor</th>
            <th class="text-center">Status</th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id_meta_funcao">
            <td>{{ `${item.id_indicador} - ${item.indicador}` }}</td>
            <td class="text-center">{{ item.tipo_meta | TitleCase }}</td>
            <td class="text-center">
              {{ item.meta_valor | BrazilianCurrency }}
            </td>
            <td class="text-center">
              <v-chip
                :color="statusAtivo[item.ativo].color"
                dark
                @click="statusItem(item)"
              >
                {{ statusAtivo[item.ativo].text }}
              </v-chip>
            </td>
            <td class="text-center">
              <v-btn
                v-can-access="204"
                icon
                @click="editItem(item)"
                color="orange"
                dark
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                v-can-access="205"
                icon
                @click="deleteItem(item)"
                class="ml-2"
                color="red"
                dark
                small
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <ModalCadMetaFuncao
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editMetaFuncao="meta"
      @close="dialog = false"
    />
  </div>
</template>

<script>
import comissao from "@/services/http/comissaoService";
import ModalCadMetaFuncao from "./ModalCadMetaFuncao";
import statusAtivo from "@/mixins/statusAtivo";
import banderiras from "@/mixins/bandeiras";
import currencyMixin from "@/mixins/currencyMixin";
export default {
  props: {
    items: {
      type: Array
    }
  },

  components: {
    ModalCadMetaFuncao
  },

  mixins: [statusAtivo, banderiras, currencyMixin],

  data() {
    return {
      dialog: false,
      labelBtn: "Salvar",
      meta: {}
    };
  },

  watch: {
    dialog(value) {
      if (value == false) {
        this.$emit("reload");
      }
    }
  },

  methods: {
    editItem(item) {
      this.meta = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deseja deletar o parâmetro?",
          "Essa ação não pode ser desfeita"
        );
        comissao()
          .metaFuncao(item.id_meta_funcao)
          .delete(
            {},
            {
              notification: true,
              message: "Meta deletada com sucesso!"
            }
          );
      } catch (error) {
        console.log(error);
      }
    },

    async statusItem(item) {
      await this.$swal.confirm(
        `Atualmente o status é ${item.ativo == "S" ? "ativo" : "inativo"}`,
        `Alterar o status da meta?`
      );
      this.loading = true;
      item.ativo = item.ativo == "S" ? "N" : "S";
      comissao()
        .metaFuncao(item.id_meta_funcao)
        .update(item, { notification: true });
    }
  }
};
</script>

<style></style>
