<template>
  <v-container class="container-card">
    <v-card>
      <v-tabs v-model="tab" class="pa-2">
        <v-tab>
          Meta Função
        </v-tab>
        <v-tab>
          Empresa Função
        </v-tab>
        <v-tab>
          Progressiva Função
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <MetaFuncao />
        </v-tab-item>
        <v-tab-item>
          <EmpresaFuncao />
        </v-tab-item>
        <v-tab-item>
          <ProgressivaFuncao />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import MetaFuncao from "@/components/comissao/metas/funcao/metaFuncao/MetaFuncao";
import EmpresaFuncao from "@/components/comissao/metas/funcao/empresaFuncao/EmpresaFuncao";
import ProgressivaFuncao from "@/components/comissao/metas/funcao/progressivaFuncao/ProgressivaFuncao";
export default {
  components: {
    MetaFuncao,
    EmpresaFuncao,
    ProgressivaFuncao
  },

  data() {
    return {
      tab: null
    };
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Meta Função");
  }
};
</script>

<style scoped>
.v-tab--active {
  background-color: white;
}
</style>
