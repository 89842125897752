<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Meta Função</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <RegisterBottom
            v-can-access="762"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <FilterProgressivaFuncao
            @selectedFilters="fetchMetaProgressivaFuncao((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <v-icon :color="bandeiras[item.id_band].color" dark>
          {{ bandeiras[item.id_band].icon }}
        </v-icon>
      </template>
      <template v-slot:[`item.funcao`]="{ item }">
        {{ `${item.cod_funcao} - ${item.funcao}` | TitleCase }}
      </template>
      <template v-slot:[`item.indicador`]="{ item }">
        {{ `${item.id_indicador} - ${item.indicador}` | TitleCase }}
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip :color="statusAtivo[item.ativo].color" dark @click="statusItem">
          {{ statusAtivo[item.ativo].text }}
        </v-chip>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          v-can-access="763"
          icon
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          v-can-access="765"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->
        <IconBottom :name="'edit'" v-can-access="763" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="765"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalCadProgressivaFuncao
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editMetaProgFuncao="meta"
      @close="(dialog = false), fetchMetaProgressivaFuncao()"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable";
import IconBottom from "@/components/shared/bottons/IconBottom";
import comissao from "@/services/http/comissaoService";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import statusAtivo from "@/mixins/statusAtivo";
import banderiras from "@/mixins/bandeiras";
import FilterProgressivaFuncao from "../Filter";
import ModalCadProgressivaFuncao from "./ModalCadProgressivaFuncao";
export default {
  components: {
    BaseTable,
    RegisterBottom,
    FilterProgressivaFuncao,
    ModalCadProgressivaFuncao,
    IconBottom
  },

  mixins: [statusAtivo, banderiras],

  data() {
    return {
      loading: false,
      dialog: false,
      search: "",
      labelBtn: "Salvar",
      filters: "",
      headers: [
        { text: "Segmento", value: "id_band" },
        { text: "Empresa", value: "apelido" },
        { text: "Função", value: "funcao" },
        { text: "Indicador", value: "indicador" },
        { text: "QTD de", value: "qtde_de", align: "right" },
        { text: "QTD ate", value: "qtde_ate", align: "right" },
        { text: "Status", value: "ativo", align: "center" },
        { text: "Ações", value: "acoes" }
      ],
      info: [],
      meta: {}
    };
  },

  methods: {
    async fetchMetaProgressivaFuncao(filters) {
      let {
        data: { data }
      } = await comissao()
        .metaProgressivaFuncao()
        .show({ per_page: -1, ...filters });

      this.info = data;
    },

    editItem(item) {
      this.meta = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          `Deseja deletar o parâmetro ${item.funcao}?`,
          "Essa ação não pode ser desfeita"
        );
        comissao()
          .metaProgressivaFuncao(item.id_meta_prog_funcao)
          .delete(
            {},
            {
              notification: true,
              message: "Meta deletada com sucesso!"
            }
          );
      } catch (error) {
        console.log(error);
      } finally {
        this.fetchMetaProgressivaFuncao();
      }
    },
    async statusItem(item) {
      await this.$swal.confirm(
        `Atualmente o status é ${item.ativo == "S" ? "ativo" : "inativo"}`,
        `Alterar o status da meta?`
      );
      this.loading = true;
      item.ativo = item.ativo == "S" ? "N" : "S";
      comissao()
        .metaProgressivaFuncao(item.id_meta_prog_funcao)
        .update(item, { notification: true });
    }
  },

  mounted() {
    this.fetchMetaProgressivaFuncao();
  }
};
</script>

<style></style>
