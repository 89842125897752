var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.info,"loading":_vm.loading,"paginate":true,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"cod_funcao","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Meta Função")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(202),expression:"202"}],on:{"click":function($event){(_vm.dialog = true), (_vm.labelBtn = 'Salvar')}}}),_c('FilterMetaFuncao',{on:{"selectedFilters":function($event){_vm.fetchMetaFuncao((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.bandeiras[item.id_band].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.bandeiras[item.id_band].icon)+" ")])]}},{key:"item.funcao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(((item.cod_funcao) + " - " + (item.funcao))))+" ")]}},{key:"item.qtd_indicadores",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dados.length)+" ")]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [(!isExpanded)?_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(" expandir "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(" ocultar "),_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('ListaMetaFuncao',{attrs:{"items":item.dados},on:{"reload":function($event){return _vm.fetchMetaFuncao(_vm.filters)}}})],1)]}}],null,true)}),_c('ModalCadMetaFuncao',{attrs:{"dialog":_vm.dialog,"labelBtn":_vm.labelBtn},on:{"close":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }