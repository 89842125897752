<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{ labelBtn != "Salvar" ? "Editar Meta" : "Cadastrar Meta" }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" md="4" lg="4">
              <BaseSelect
                prepend-inner-icon="mdi-file-table-box-multiple-outline"
                clearable
                single-line
                label="Segmento"
                item-text="descricao"
                item-value="id_band"
                service="sistemaService.tipoBandeiras"
                :filters="{ id_band: '2,4' }"
                v-model="form.id_band"
                :rules="[rules.required]"
                :disabled="locked"
                :isCompany="true"
              />
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <BaseSelect
                label="Empresa"
                v-model="form.id_empresa"
                service="sistemaService.empresa"
                item-text="apelido"
                item-value="id_empresa"
                prepend-inner-icon="mdi-domain"
                :rules="[rules.required]"
                clearable
                :isCompany="true"
                :multiple="false"
                :disabled="locked"
                :filters="{ id_band: form.id_band, calcula_comissao: 'S' }"
              />
            </v-col>

            <v-col cols="12" md="4" lg="4">
              <BaseSelect
                label="Função"
                service="sistemaService.funcoes"
                v-model="form.id_funcao"
                item-text="descricao"
                item-value="id_funcao"
                prepend-inner-icon="mdi-account-tie"
                clearable
                chips
                single-line
                :form="{ id_band: form.id_band, ativo: 'S' }"
                :hasCode="true"
                :multiple="false"
                :disabled="locked"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <BaseSelect
                label="Indicador"
                service="sistemaService.indicadores"
                v-model="form.id_indicador"
                item-text="indicador"
                item-value="id_indicador"
                prepend-inner-icon="mdi-finance"
                clearable
                chips
                :form="{ ativo: 'S' }"
                single-line
                :hasCode="true"
                :multiple="false"
                :disabled="locked"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col cols="12" md="4" lg="4">
              <BaseSelect
                label="Tipo Meta"
                service="campanhaService.tipoMeta"
                v-model="form.id_tipo_meta"
                item-text="tipo_meta"
                item-value="id_tipo_meta"
                prepend-inner-icon="mdi-trending-up"
                clearable
                chips
                :form="{ ativo: 'S' }"
                single-line
                :multiple="false"
                :disabled="locked"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col class="pt-5" cols="12" md="4" lg="4">
              <vuetify-money
                label="Meta Valor"
                :rules="[rules.required]"
                v-model="form.meta_valor"
                valueWhenIsEmpty="0"
                :options="optionsVuetifyMoney"
              >
              </vuetify-money>
            </v-col>

            <v-col cols="12" md="4" lg="4">
              <v-autocomplete
                label="Status"
                v-model="form.ativo"
                :items="[
                  { text: 'Ativo', value: 'S' },
                  { text: 'Inativo', value: 'N' }
                ]"
                :rules="[rules.required]"
                :multiple="false"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? put() : send()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import comparator from "@/mixins/comparatorValue";
import BaseSelect from "@/components/shared/BaseSelectService";
import comissao from "@/services/http/comissaoService";
export default {
  name: "ModalCadMetaIndividual",

  mixins: [rules, comparator],

  components: {
    BaseSelect
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    },
    editMetaFuncao: {
      type: Object
    }
  },

  data() {
    return {
      form: {},
      locked: false,
      optionsVuetifyMoney: {
        locale: "pt-BR",
        prefix: "R$",
        length: 11,
        precision: 2
      }
    };
  },

  watch: {
    editMetaFuncao(value) {
      this.form = value;
    },
    labelBtn(value) {
      this.locked = value == "Editar" ? true : false;
    }
  },

  methods: {
    async send() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .metaFuncao()
          .store(this.form, {
            notification: true,
            message: "Meta cadastrada com sucesso!"
          });
      }
    },

    async put() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .metaFuncao(this.form.id_meta_funcao)
          .update(this.form, {
            notification: true,
            message: "Meta editada com sucesso!"
          });
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
