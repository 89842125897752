<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Meta Função</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="747"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <FilterEmpresaFuncao
            @selectedFilters="fetchMetaFuncao((filters = $event))"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.id_band`]="{ item }">
        <v-icon :color="bandeiras[item.id_band].color" dark>
          {{ bandeiras[item.id_band].icon }}
        </v-icon>
      </template>
      <template v-slot:[`item.desc_funcao`]="{ item }">
        {{ `${item.cod_funcao} - ${item.desc_funcao}` | TitleCase }}
      </template>
      <template v-slot:[`item.indicador`]="{ item }">
        {{ `${item.id_indicador} - ${item.indicador}` | TitleCase }}
      </template>
      <template v-slot:[`item.meta_valor`]="{ item }">
        {{ item.meta_valor | BrazilianCurrency }}
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip
          :color="statusAtivo[item.ativo].color"
          dark
          @click="statusItem(item)"
        >
          {{ statusAtivo[item.ativo].text }}
        </v-chip>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          v-can-access="564"
          icon
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-can-access="565"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom :name="'edit'" v-can-access="564" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="565"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalCadEmpresaFuncao
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editMetaFuncao="meta"
      @close="dialog = false"
    />
  </div>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable";
import comissao from "@/services/http/comissaoService";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import statusAtivo from "@/mixins/statusAtivo";
import banderiras from "@/mixins/bandeiras";
import FilterEmpresaFuncao from "../Filter";
import ModalCadEmpresaFuncao from "./ModalCadEmpresaFuncao";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  components: {
    BaseTable,
    RegisterBottom,
    FilterEmpresaFuncao,
    ModalCadEmpresaFuncao,
    IconBottom
  },

  mixins: [statusAtivo, banderiras, currencyMixin],

  data() {
    return {
      loading: false,
      dialog: false,
      search: "",
      labelBtn: "Salvar",
      filters: "",
      headers: [
        { text: "Segmento", value: "id_band" },
        { text: "Empresa", value: "apelido" },
        { text: "Função", value: "desc_funcao" },
        { text: "Indicador", value: "indicador" },
        { text: "Tipo Meta", value: "tipo_meta" },
        { text: "Meta Valor", value: "meta_valor", align: "right" },
        { text: "Status", value: "ativo", align: "center" },
        { text: "Ações", value: "acoes" }
      ],
      info: [],
      meta: {}
    };
  },

  methods: {
    async fetchMetaFuncao(filters) {
      let {
        data: { data }
      } = await comissao()
        .metaEmpresaFuncao()
        .show({ per_page: -1, ...filters });

      this.info = data;
    },

    editItem(item) {
      this.meta = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deseja deletar o parâmetro?",
          "Essa ação não pode ser desfeita"
        );
        comissao()
          .metaFuncao(item.id_meta_funcao)
          .delete(
            {},
            {
              notification: true,
              message: "Meta deletada com sucesso!"
            }
          );
      } catch (error) {
        console.log(error);
      }
    },

    async statusItem(item) {
      await this.$swal.confirm(
        `Atualmente o status é ${item.ativo == "S" ? "ativo" : "inativo"}`,
        `Alterar o status da meta?`
      );
      this.loading = true;
      item.ativo = item.ativo == "S" ? "N" : "S";
      comissao()
        .metaEmpresaFuncao(item.id_meta_funcao)
        .update(item, { notification: true });
    }
  },

  mounted() {
    this.fetchMetaFuncao();
  }
};
</script>

<style></style>
